export default {
    computed: {
        headers() {
            return [
                {
                    value: 'name',
                    text: this.$t('labels.name'),
                    type: 'string',
                },
                {
                    value: 'link',
                    text: this.$t('labels.link'),
                    type: 'string',
                },
                {
                    value: 'bannerTitle',
                    text: this.$t('labels.title'),
                    type: 'string',
                },
                {
                    value: 'isActive',
                    text: this.$t('labels.active'),
                    type: 'boolean',
                },
            ]
        },
    },
}
