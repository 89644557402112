<template>
    <table-container>
        <data-iterator ref="iterator" :api="api" :filters.sync="filters">
            <template v-slot:filters="{ busy }">
                <table-filter
                    v-model="filters"
                    :fields="headers"
                    :busy="busy"
                    hide-filter-box
                ></table-filter>
            </template>

            <template v-slot:actions>
                <slot name="actions"></slot>
            </template>

            <template slot-scope="{ items }">
                <d-table
                    :headers="headers"
                    :items="items"
                    :filters.sync="filters"
                    row-select
                    row-action
                    @onRowSelected="onRowSelected"
                >
                    <template v-slot:[`item.isActive`]="{ item }">
                        <checkbox-input
                            :value="item.isActive"
                            class="mt-0"
                            hide-details
                            @input="
                                item.isActive
                                    ? confirmDisable(item)
                                    : confirmEnable(item)
                            "
                        ></checkbox-input>
                    </template>

                    <!-- row actions -->
                    <template v-slot:rowActions="{ item }">
                        <edit-button :to="getEditLink(item)"></edit-button>
                        <delete-action
                            :entity-name="item.name"
                            :entity-id="item.id"
                            :api="api"
                            strong
                            @deleted="onEntityDeleted"
                            @failed="onEntityDeleteFailed"
                        ></delete-action>
                    </template>
                </d-table>
            </template>
        </data-iterator>

        <confirm-modal
            :value="showEnableModal"
            :text="
                $t('texts.confirm_promo_banner_enable', {
                    banner: itemSelectedChange ? itemSelectedChange.name : '',
                })
            "
            :processing="processingChange"
            @onApprove="doEnable"
            @onCancel="closeStatusModals"
        ></confirm-modal>
        <confirm-modal
            :value="showDisableModal"
            :text="
                $t('texts.confirm_promo_banner_disable', {
                    banner: itemSelectedChange ? itemSelectedChange.name : '',
                })
            "
            :processing="processingChange"
            @onApprove="doDisable"
            @onCancel="closeStatusModals"
        ></confirm-modal>
    </table-container>
</template>

<script>
import routeType from '@/router/routeType'
import RestApiType from '@/api/RestApiType'
import DataIteratorMixin from '@/components/mixins/DataIteratorMixin'
import DataTableMixin from '@/components/mixins/DataTableMixin'
import Headers from './mixins/Headers'
import CheckboxInput from '@/components/form/Inputs/CheckboxInput.vue'
import ConfirmModal from '@/components/confirm/ConfirmModal.vue'
import CloneDeep from 'lodash/cloneDeep'
import PromoBannersApiClient from '@/api/RestApi/PromoBannersApiClient'
import PromoBannerFormMapper from '@/services/mapper/PromoBannerFormMapper'

export default {
    components: { ConfirmModal, CheckboxInput },
    mixins: [DataIteratorMixin, DataTableMixin, Headers],
    data() {
        return {
            showEnableModal: false,
            showDisableModal: false,
            itemSelectedChange: null,
            processingChange: false,
            api: RestApiType.PROMO_BANNERS,
        }
    },
    methods: {
        closeStatusModals() {
            this.showEnableModal = false
            this.showDisableModal = false
        },

        async saveActive(isActive) {
            this.processingChange = true

            const entity = PromoBannerFormMapper.toEntity(
                this.itemSelectedChange
            )

            entity.isActive = isActive
            await PromoBannersApiClient.update(entity, entity.id)

            this.$refs.iterator.updateItems(true).then(() => {
                this.closeStatusModals()
            })
        },
        doEnable() {
            this.saveActive(true)
        },
        doDisable() {
            this.saveActive(false)
        },
        confirmEnable(item) {
            this.itemSelectedChange = CloneDeep(item)
            this.showEnableModal = true
        },

        confirmDisable(item) {
            this.itemSelectedChange = CloneDeep(item)
            this.showDisableModal = true
        },
        getEditLink(item) {
            return {
                name: routeType.PROMO_BANNERS_EDIT,
                params: { id: item.id },
            }
        },
    },
}
</script>
