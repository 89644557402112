var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table-container',[_c('data-iterator',{ref:"iterator",attrs:{"api":_vm.api,"filters":_vm.filters},on:{"update:filters":function($event){_vm.filters=$event}},scopedSlots:_vm._u([{key:"filters",fn:function(ref){
var busy = ref.busy;
return [_c('table-filter',{attrs:{"fields":_vm.headers,"busy":busy,"hide-filter-box":""},model:{value:(_vm.filters),callback:function ($$v) {_vm.filters=$$v},expression:"filters"}})]}},{key:"actions",fn:function(){return [_vm._t("actions")]},proxy:true},{key:"default",fn:function(ref){
var items = ref.items;
return [_c('d-table',{attrs:{"headers":_vm.headers,"items":items,"filters":_vm.filters,"row-select":"","row-action":""},on:{"update:filters":function($event){_vm.filters=$event},"onRowSelected":_vm.onRowSelected},scopedSlots:_vm._u([{key:"item.isActive",fn:function(ref){
var item = ref.item;
return [_c('checkbox-input',{staticClass:"mt-0",attrs:{"value":item.isActive,"hide-details":""},on:{"input":function($event){item.isActive
                                ? _vm.confirmDisable(item)
                                : _vm.confirmEnable(item)}}})]}},{key:"rowActions",fn:function(ref){
                                var item = ref.item;
return [_c('edit-button',{attrs:{"to":_vm.getEditLink(item)}}),_c('delete-action',{attrs:{"entity-name":item.name,"entity-id":item.id,"api":_vm.api,"strong":""},on:{"deleted":_vm.onEntityDeleted,"failed":_vm.onEntityDeleteFailed}})]}}],null,true)})]}}],null,true)}),_c('confirm-modal',{attrs:{"value":_vm.showEnableModal,"text":_vm.$t('texts.confirm_promo_banner_enable', {
                banner: _vm.itemSelectedChange ? _vm.itemSelectedChange.name : '',
            }),"processing":_vm.processingChange},on:{"onApprove":_vm.doEnable,"onCancel":_vm.closeStatusModals}}),_c('confirm-modal',{attrs:{"value":_vm.showDisableModal,"text":_vm.$t('texts.confirm_promo_banner_disable', {
                banner: _vm.itemSelectedChange ? _vm.itemSelectedChange.name : '',
            }),"processing":_vm.processingChange},on:{"onApprove":_vm.doDisable,"onCancel":_vm.closeStatusModals}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }